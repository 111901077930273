import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { isEnterCmdCtrl } from '@/src/modules/keyboardShortcuts/utils';

export const useKbdCmdOrCtrlAndEnter = (onEvent: VoidFunction) => {
  useWindowEvent('keydown', (e) => {
    if (isEnterCmdCtrl(e)) {
      onEvent();
      e.preventDefault();
      e.stopPropagation();
    }
  });
};
