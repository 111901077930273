import isUrl from 'is-url';
import URL from 'url';
// Wrapper around isUrl that tries with https:// if it fails, just in case the protocol is missing
export const isUrlWithProtocol = (url: string) => {
  const parsedUrl = URL.parse(url);
  if (parsedUrl.protocol) return isUrl(url);

  return isUrl(`https://${url}`);
};

// This will preprend https:// if the protocol is missing
export const formatUrlWithProtocol = (url: string) => {
  const parsedUrl = URL.parse(url);
  if (parsedUrl.protocol) return url;

  return `https://${url}`;
};
