import { cssTextEllipsis, mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

/**
 * should be probably replaced with a more generic component
 */
export const NewResourceInputContainer = styled.div`
  background: ${cssVar['color-bg-secondary']};
  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: ${cssVar['radius-input']};
  overflow: hidden;
  padding-right: 6px;
  padding-left: 0.875rem;
  gap: 0.5rem;
  width: 100%;
  flex-shrink: 0;

  display: flex;
  align-items: center;

  &:focus-within {
    border-style: solid;
    border-color: ${cssVar['color-app-primary']};
  }

  & > input {
    ${cssTextEllipsis};
    background: transparent;
    line-height: 44px;
    flex-grow: 1;
    color: ${cssVar['color-text-primary']};
    cursor: text;
    &:focus {
      outline: none;
      border: none !important;
    }
  }

  ${mediaMobile} {
    background: ${cssVar['color-bg-secondary-button']};

    border: none;

    &:focus-within {
      border: none;
    }
  }
`;
