import FolderIcon from '@/public/images/icons/Folder.png';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const DestinationButtonBase = styled.button`
  width: 200px;
  border-radius: 40px;
  border: 1px dashed ${cssVar['color-border-primary']};
  background: ${cssVar['color-bg-secondary']};
  color: ${cssVar['color-text-secondary']};
  display: flex;
  height: 38px;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  > span {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
  }

  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.3);
  color: ${cssVar['color-text-primary-reverse']};
  transition: 0.2s all;
  &:hover {
    background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.33);
  }
  ${mediaMobile} {
    position: static;
    min-width: 200px;
    width: auto;
    flex-grow: 1;
    margin-right: auto;
    transform: none;
    color: ${cssVar['color-text-primary']};
    background: none;
  }
`;

export const DestinationButton: React.FC<{ className?: string }> = ({ className }) => {
  const { destinationResourceId, handleOpenDestinationFolder } = useNewResourceContext();

  const { folder } = useQueryFolder(destinationResourceId);

  const title = !folder
    ? ''
    : folder.id === folder.listData?.id // we take the space title, the data.name is not updated if it's a space
      ? folder.listData.title
      : folder.data.name;

  return (
    <DestinationButtonBase
      onClick={handleOpenDestinationFolder}
      type="button"
      className={className}
    >
      <img src={FolderIcon.src} alt="Folder" width={16} />
      <span>{title}</span>
    </DestinationButtonBase>
  );
};
