import { pick } from '@/src/lib/store';
import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import useUIStore from '@/src/store/ui';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';

import { EditorJSData } from '@/src/components/Tiptap/editorjs.types';
import Tiptap from '@/src/components/Tiptap/Tiptap';
import { TiptapDataValue } from '@/src/components/Tiptap/types';
import { useUnmount } from '@/src/hooks/useUnmount';
import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResource } from '@/src/modules/resources/mutations/useMutationCreateResource';
import { Flex } from '@/src/modules/ui/components/Flex';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { FabricResourceTypes } from '@fabric/woody-client';
import { Editor } from '@tiptap/react';
import styled from 'styled-components';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  title: yup.string().max(500, 'Title is too long'),
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

export const ModalContentNoteMobile: React.FC = () => {
  const { newModalOptions } = useUIStore((s) => pick(s, ['newModalOptions']), shallow);

  const { dataTransfer, text } = newModalOptions;

  const pastedContent = React.useMemo(() => {
    return dataTransfer ?? text;
  }, [dataTransfer, text]);

  const { destinationResourceId, selectedTags, handleClose } = useNewResourceContext();

  const { mutate: mutateCreateResource, isPending, isSuccess } = useMutationCreateResource();

  const [editor, setEditor] = useState<Editor | null>(null);
  const editorJsDataRef = React.useRef<TiptapDataValue | undefined>(undefined);
  const onFabricEditorChange = React.useCallback((data: TiptapDataValue) => {
    editorJsDataRef.current = data;
  }, []);

  const handleSave = async ({ title, comment }: { title: string; comment?: string }) => {
    if (isPending || isSuccess) {
      return;
    }

    if (!editorJsDataRef.current) {
      return;
    }
    const editorjs = editorJsDataRef.current as EditorJSData;
    const isTextEmpty =
      editorjs?.blocks.every((block) => block.type === 'paragraph' && block.data.text === '') ??
      true;

    const isMissingEditorData = !editor || editor.isEmpty || isTextEmpty;

    if (isMissingEditorData && title === '') {
      return;
    }

    return mutateCreateResource({
      createResourceData: {
        type: FabricResourceTypes.NOTEPAD,
        parentResourceId: destinationResourceId ?? '',
        title: title || '',
        editorjs,
      },
      tags: selectedTags,
      comment,
    });
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      comment: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({ title, comment }, formik) => {
      await handleSave({
        title,
        comment,
      });
      formik.setSubmitting(false);
      handleClose();
    },
  });

  /**
   * @REF [save on unmount]
   */
  useUnmount(formik.submitForm, true);

  return (
    <Flex flexGrow gap={24} direction="column" style={{ padding: '1rem' }}>
      <Header
        label={
          <StyledTextInput
            data-no-autofocus="true"
            variant="discreet"
            value={formik.values.title}
            name="title"
            onChange={formik.handleChange}
            placeholder="Untitled note"
            inputHeight="sm"
            autoFocus={false}
            containerProps={{
              flexGrow: 1,
            }}
          />
        }
      />
      <Flex direction="column" gap="elementsContainer" flexGrow>
        <TiptapWrapper>
          <Tiptap
            pastedContent={pastedContent}
            onChange={onFabricEditorChange}
            editorRef={setEditor}
            autoFocus
          />
        </TiptapWrapper>
      </Flex>
      <SubmitFooter
        submitDisabled={formik.isSubmitting || (formik.values.title === '' && !!editor?.isEmpty)}
        /**
         * autosaving on unmount so we just need to close on submit
         * see @REF [save on unmount]
         */
        onSubmit={handleClose}
      />
    </Flex>
  );
};

const StyledTextInput = styled(TextInput)`
  font-size: 16px;
  font-weight: 400;
`;

const TiptapWrapper = styled.div`
  flex-grow: 1;
  background: ${cssVar['color-bg-secondary-button']};
  ${mediaMobile} {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
  }
  .tiptap {
    padding: 1rem;
  }
`;
