import styles from './FileUpload.module.scss';

import CloseIcon from '@/src/icons/CloseIcon';
import { NewResourceInputContainer } from '@/src/modules/resources/components/NewResource/components/TextInputContainer';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ClearInputButton } from '@/src/modules/ui/components/TextInput/TextInput';
import { P } from '@/src/modules/ui/components/Typography';
import Image from 'next/image';
import plur from 'plur';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import FileDisplay from '../../../../../components/FileDisplay/FileDisplay';
import useFilesUpload from '../../../../../hooks/fileUpload';
import useFileUploadStore from '../../../../../store/fileUploadStore';
import { UploadableFile } from '../../../../../types/file';

const FileExtension = styled(P).attrs((props) => {
  return {
    color: 'quaternary',
    as: 'span',
    ...props,
  };
})`
  overflow: visible;
`;

const dropdownContentProps = {
  align: 'start' as const,
};

export const FileUpload = () => {
  const [fileUploadInputRef, setFileUploadInputRef] = useState<HTMLInputElement | null>(null);
  const [folderUploadInputRef, setFolderUploadInputRef] = useState<HTMLInputElement | null>(null);

  const handleFiles = useFilesUpload();

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files ?? []);
    if (files.length < 1) return;
    clearFiles();

    handleFiles(files);
  };

  const { files, clearFiles } = useFileUploadStore(
    (state) => ({ files: state.files, clearFiles: state.clearFiles }),
    shallow,
  );

  const uploadedFile = useMemo(() => files[0], [files]);

  const [objectURL, setObjectURL] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!uploadedFile?.file) return;

    const url = URL.createObjectURL(uploadedFile.file);
    setObjectURL(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [uploadedFile?.file]);

  const nameWithoutExtension = uploadedFile?.file?.name?.split('.').slice(0, -1).join('.');
  const extension = uploadedFile?.file?.name?.split('.').pop();

  const onClickClearFiles = () => {
    clearFiles();

    // Makes sure re-selecting the same file will still work.
    if (fileUploadInputRef) {
      fileUploadInputRef.value = '';
      fileUploadInputRef.files = null;
    }

    // Makes sure re-selecting the same folder will still work.
    if (folderUploadInputRef) {
      folderUploadInputRef.value = '';
      folderUploadInputRef.files = null;
    }
  };

  const uploadableFileToIcon = (uploadableFile: UploadableFile, style?: CSSProperties) => {
    return (
      <div className={styles['uploads-file-icon']} style={style}>
        {uploadableFile.file.type.startsWith('image') ? (
          <Image src={objectURL!} alt={uploadableFile.file.name} width={40} height={30} />
        ) : (
          <FileDisplay
            fileExtension={uploadableFile.file.name.split('.').pop() ?? '?'}
            fileName={uploadableFile.file.name}
            compact
            hideFileName
            scale={0.5}
          />
        )}
      </div>
    );
  };

  return (
    <DropdownMenu
      contentProps={dropdownContentProps}
      renderTriggerElement={({ onOpenChange }) => (
        <DropdownMenu.Trigger
          onOpenChange={onOpenChange}
          className={styles['uploads-list']}
          style={{
            cursor: files.length > 0 ? 'default' : 'pointer',
          }}
        >
          <input
            ref={setFileUploadInputRef}
            type="file"
            multiple
            onChange={onFileChange}
            className={styles.hidden}
            data-testid="file-upload-input"
          />

          <input
            ref={setFolderUploadInputRef}
            type="file"
            multiple
            onChange={onFileChange}
            className={styles.hidden}
            directory=""
            webkitdirectory=""
            data-testid="folder-upload-input"
          />

          <NewResourceInputContainer>
            <Flex
              style={{ height: 44 }}
              justifyContent="space-between"
              overflow="hidden"
              alignItems="center"
              gap="elementsContainer"
              flexGrow={1}
            >
              {files.length > 0 ? (
                <>
                  {/** overflow hidden to elipsis the file name */}
                  <Flex
                    alignItems="center"
                    gap="elementsContainer"
                    overflow="hidden"
                    justifyContent="space-between"
                  >
                    {uploadableFileToIcon(uploadedFile)}
                    <Flex alignItems="center" overflow="hidden">
                      <P ellipsis>
                        {files.length === 1 && nameWithoutExtension}
                        {files.length > 1 && `${files.length} ${plur('file', files.length)}`}
                      </P>

                      {files.length === 1 && <FileExtension>.{extension}</FileExtension>}
                    </Flex>
                  </Flex>
                  <ClearInputButton onPointerDown={onClickClearFiles}>
                    <CloseIcon />
                  </ClearInputButton>
                </>
              ) : (
                <P color="placeholder">Upload files...</P>
              )}
            </Flex>
          </NewResourceInputContainer>
        </DropdownMenu.Trigger>
      )}
    >
      <DropdownMenu.Group>
        <DropdownMenu.Item
          onClick={() => {
            fileUploadInputRef?.click();
          }}
        >
          Upload files...
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={() => {
            folderUploadInputRef?.click();
          }}
        >
          Upload folder...
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </DropdownMenu>
  );
};

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string; // remember to make these attributes optional....
    webkitdirectory?: string;
  }
}
