import TagButton from '@/src/components/Tags/Buttons/TagButton';
import { useResponsive } from '@/src/hooks/responsive';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React from 'react';
import styled from 'styled-components';

const HeaderBase = styled(Flex).attrs({
  gap: 'elementsContainer',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  color: ${cssVar['color-text-primary']};
`;

const Title = styled(DialogTitle)`
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.875rem;
`;

const StyledTagButton = styled(TagButton)`
  ${mediaMobile} {
    position: static;

    background: ${cssVar['color-bg-secondary-button']};
    color: ${cssVar['color-text-primary']};
    box-shadow: none;
  }
`;

export const Header: React.FC<{
  minLabel?: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
}> = ({ label, minLabel, icon }) => {
  const { selectedTags, onSelectTag } = useNewResourceContext();
  const { isMobileView } = useResponsive();

  return (
    <>
      <VisuallyHidden>
        <DialogDescription>New resource</DialogDescription>
      </VisuallyHidden>
      <HeaderBase>
        <Flex alignItems="center" gap="element">
          {icon}
          <Title>{(isMobileView && minLabel) || label}</Title>
        </Flex>
        <Flex gap="element">
          <StyledTagButton onSelect={onSelectTag} selectedTags={selectedTags} />
          <Modal.Close />
        </Flex>
      </HeaderBase>
    </>
  );
};
