import { Buffer } from 'buffer';
import React, { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ShareIntentResult, mobileStore } from '../hooks/mobile';
import { isInteractiveElement } from '../utils/elements';
import { DragAndDropArea } from './DragAndDropArea/DragAndDropArea';

import { ReadFile } from '@/mobile/readfile';
import { StoredDataTransfer } from '@/src/lib/clipboard';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import isUrl from 'is-url';
import useFilesUpload from '../hooks/fileUpload';
import useUIStore, { setNewModalOpen } from '../store/ui';

const DragAndDropProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { expandedFdocId, newModalOptions } = useUIStore(
    (state) => ({ expandedFdocId: state.expandedFdocId, newModalOptions: state.newModalOptions }),
    shallow,
  );

  const openNewFdocWithText = useCallback(
    ({
      text,
      key,
      shared = false,
      comment,
      dataTransfer,
    }: {
      text: string;
      key: 'text' | 'url';
      shared?: boolean;
      comment?: string;
      dataTransfer?: StoredDataTransfer;
    }) => {
      // ignore event if we are focusing on an interactive element
      if (isInteractiveElement(document.activeElement) && !shared) return;
      // ignore event if we are already in the expanded fdoc page
      if (expandedFdocId && !shared) return;

      setNewModalOpen(true, {
        ...(key === 'text'
          ? {
              type: NewResourceType.Note,
              text,
              dataTransfer,
            }
          : {
              type: NewResourceType.Link,
              url: text,
              comment,
            }),
        shared,
      });
    },
    [expandedFdocId],
  );

  const openNewFdocWithFile = useCallback(
    (shared = false) => {
      setNewModalOpen(true, {
        ...newModalOptions,
        type: NewResourceType.Upload,
        shared,
      });
    },
    [newModalOptions],
  );

  const { sharedItems, setSharedItems } = mobileStore(
    (state) => ({
      sharedItems: state.sharedItems,
      setSharedItems: state.setSharedItems,
    }),
    shallow,
  );

  const uploadFile = useFilesUpload();

  useEffect(() => {
    const lastSharedItem = sharedItems[sharedItems.length - 1];

    if (!lastSharedItem) return;

    const { type, title, url } = lastSharedItem;

    let fallbackUrl: string | undefined;
    try {
      fallbackUrl = new URL(title).href;
    } catch (err) {}

    const reversedSharedItems = [...sharedItems].reverse();

    if (!fallbackUrl) {
      fallbackUrl = reversedSharedItems.find(
        (item) => item.type === 'text/plain' && isUrl(item.url),
      )?.url;
    }

    const description = !isUrl(title)
      ? title
      : reversedSharedItems.find(
          (item) => item.type === 'text/plain' && !isUrl(item.title) && !isUrl(item.url),
        )?.title;

    const openNewFdocWithIntentFiles = async (fileIntents: ShareIntentResult[]) => {
      const files = (
        await Promise.all(
          fileIntents.map(async (fileIntent) => {
            try {
              const { content, contentType } = await ReadFile.readFile({
                url: fileIntent.url,
              });

              const byteArray = Buffer.from(content, 'base64');

              const blob = new Blob([byteArray], { type: contentType });

              // make sure the file includes the extension
              const name =
                fileIntent.title.indexOf('.') > -1
                  ? fileIntent.title
                  : `${fileIntent.title}.${contentType.split('/')[1]}`;

              return new File([blob], name, { type: contentType });
            } catch (err) {
              console.log(err);
            }
          }),
        )
      ).filter(Boolean) as File[];

      uploadFile(files);
      openNewFdocWithFile(true);
    };

    if (type === 'text/plain' && !url && !fallbackUrl) {
      openNewFdocWithText({
        text: title,
        key: 'text',
        shared: true,
      });
      // clear all text/plain items
      setSharedItems((items) => items.filter((item) => item.type !== 'text/plain'));
    } else if (type === 'text/plain' && (url || fallbackUrl)) {
      openNewFdocWithText({
        text: url.length > 0 ? url : fallbackUrl!,
        key: 'url',
        shared: true,
        comment: description,
      });
      setSharedItems((items) => items.filter((item) => item.type !== 'text/plain'));
    } else {
      // if it's a file we need to check if there are multiple files in the sharedItems array
      // if there are multiple files we need to upload them all at once
      const fileIntents = sharedItems.filter((item) => item.type !== 'text/plain' && item.url);
      if (!fileIntents.length) return;

      openNewFdocWithIntentFiles(fileIntents);
      setSharedItems((items) => items.filter((item) => fileIntents.indexOf(item) === -1));

      return;
    }

    setSharedItems((items) => items.slice(0, -1));
  }, [openNewFdocWithFile, openNewFdocWithText, setSharedItems, sharedItems, uploadFile]);

  return (
    <>
      {children}
      <DragAndDropArea
        onFileNote={openNewFdocWithFile}
        onUrlNote={(url) => openNewFdocWithText({ text: url, key: 'url' })}
        onTextNote={(text, data) => openNewFdocWithText({ text, key: 'text', dataTransfer: data })}
      />
    </>
  );
};

export default DragAndDropProvider;
