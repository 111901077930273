import { isInMobile } from '@/src/hooks/mobile';
import { useResponsive } from '@/src/hooks/responsive';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { pick } from '@/src/lib/store';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import { ModalDestinationSelector } from '@/src/modules/resources/components/DestinationSelector/ModalDestinationSelector';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { NewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { ModalContentLink } from '@/src/modules/resources/components/NewResource/ModalContentLink/ModalContentLink';
import { ModalContentNoteDesktop } from '@/src/modules/resources/components/NewResource/ModalContentNote/ModalContentNoteDesktop';
import { ModalContentNoteMobile } from '@/src/modules/resources/components/NewResource/ModalContentNote/ModalContentNoteMobile';
import { ModalContentUpload } from '@/src/modules/resources/components/NewResource/ModalContentUpload/ModalContentUpload';
import { useQueryTag } from '@/src/modules/tags/queries/useQueryTag';
import Modal from '@/src/modules/ui/components/Modal';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import useUIStore from '@/src/store/ui';
import { Capacitor } from '@capacitor/core';
import { PrivateTag } from '@fabric/woody-client';
import { useRouter } from 'next/router';
import React from 'react';
import { SendIntent } from 'send-intent';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

export const ModalNewResource: React.FC = () => {
  const router = useRouter();
  const { newModalOptions, setNewModalOpen, isNewModalOpen } = useUIStore(
    (s) => pick(s, ['newModalOptions', 'setNewModalOpen', 'isNewModalOpen']),
    shallow,
  );

  const { isMobileView } = useResponsive();

  const { shared, type } = newModalOptions;
  const isOpen = isNewModalOpen && !!type;

  /************************************************************************************************************
   * destination
   */
  const destinationSelectOpenState = useBoolState(false, { preventDefault: false });
  const { handleFalse: closeDestinationSelector, handleTrue: openDestinationSelector } =
    destinationSelectOpenState;

  const listId = router.query.listId as string | undefined;
  const folderId = router.query.folderId as string | undefined;
  const { inboxRoot } = useQueryInbox();

  const [destinationResourceId, setDestinationResourceId] = React.useState<string | undefined>(
    !shared ? listId ?? folderId ?? inboxRoot?.id : inboxRoot?.id,
  );

  // the component is mounted all the time, so we need to update the destinationResourceId when the listId or folderId changes
  React.useEffect(() => {
    setDestinationResourceId(!shared ? listId ?? folderId ?? inboxRoot?.id : inboxRoot?.id);
  }, [listId, folderId, inboxRoot?.id, shared]);

  /************************************************************************************************************
   * Tags
   */
  const tagId = router.query.tagId as string | undefined;
  const { data: tag } = useQueryTag(tagId);
  const [selectedTags, setSelectedTags] = React.useState<PrivateTag[]>(tag ? [tag] : []);

  const onSelectTag = React.useCallback((tag: PrivateTag, selected: boolean) => {
    setSelectedTags((tags) => (selected ? [...tags, tag] : tags.filter((t) => t.id !== tag.id)));
  }, []);

  /************************************************************************************************************
   * Save handling
   */

  const handleClose = React.useCallback(() => {
    if (isInMobile('android') && !Capacitor.isPluginAvailable('AppLauncher')) {
      try {
        SendIntent.finish();
      } catch (e) {
        console.log('SendIntent.finish() failed');
      }
    }
    closeDestinationSelector();
    setNewModalOpen(false, { type });
    setSelectedTags([]);
  }, [setNewModalOpen, type, closeDestinationSelector]);

  /************************************************************************************************************
   * Context
   */
  const providerValue = React.useMemo(
    () => ({
      selectedTags,
      onSelectTag,
      destinationResourceId,
      handleOpenDestinationFolder: openDestinationSelector,
      handleClose,
    }),
    [selectedTags, onSelectTag, destinationResourceId, openDestinationSelector, handleClose],
  );

  return (
    <>
      {isOpen && (
        <ModalDestinationSelector
          title="Save to..."
          initialDestinationId={destinationResourceId}
          open={destinationSelectOpenState.value}
          onDestinationSelect={(data) => {
            setDestinationResourceId(data.id);
          }}
          onClose={destinationSelectOpenState.handleFalse}
          ctaButtonProps={{
            children: 'Choose',
          }}
        />
      )}
      <NewResourceContext.Provider value={providerValue}>
        {/**
         * ModalContentNoteDesktop renders a standalone modal
         */}
        {type === NewResourceType.Note && !isMobileView ? (
          <ModalContentNoteDesktop />
        ) : (
          <Modal
            open={isOpen}
            onOpenChange={(value) => {
              if (!value) {
                handleClose();
              }
            }}
          >
            <Modal.Portal>
              <Modal.Overlay visibleOnMobileViewport />
              <ModalContent style={{ overflow: 'initial' }}>
                {type === NewResourceType.Link ? (
                  <ModalContentLink />
                ) : type === NewResourceType.Upload ? (
                  <ModalContentUpload />
                ) : type === NewResourceType.Note ? (
                  <ModalContentNoteMobile />
                ) : null}
              </ModalContent>
            </Modal.Portal>
          </Modal>
        )}
      </NewResourceContext.Provider>
    </>
  );
};

const ModalContent = styled(Modal.Content)`
  margin-top: env(safe-area-inset-top, 0px);
  padding-top: 0;
  overflow: initial;
  padding-bottom: max(env(safe-area-inset-bottom, 0px), var(--keyboard-height, 0px));
  ${mediaMobile} {
    height: calc(100dvh - env(safe-area-inset-top, 0px));
    html[data-viewport='true'] & {
      height: calc(min(100dvh, var(--viewport-height)) - env(safe-area-inset-top, 0px));
    }
    // radiuses only when there's top inset
    border-top-left-radius: min(env(safe-area-inset-top, 0px), 32px);
    border-top-right-radius: min(env(safe-area-inset-top, 0px), 32px);
    border: none !important;
  }
`;
