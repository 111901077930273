import AlertIcon from '@/public/images/icons/Alert.svg';
import useAuthStore from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { isSubscribedPlan } from '@/src/types/pricing';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import clsx from 'clsx';
import Link from 'next/link';
import plur from 'plur';
import React from 'react';
import { shallow } from 'zustand/shallow';
import styles from './OverSizedInfo.module.scss';

const OverSizedInfo: React.FC<{
  amount: number;
  className?: string;
  onClose?: () => void;
}> = ({ amount, className, onClose }) => {
  const user = useAuthStore((state) => state.user, shallow);

  const { isMobile } = useResponsive();

  if (isSubscribedPlan(user?.subscription.tier ?? 'free')) return null;

  return (
    <div className={clsx(styles.root, className)}>
      <AlertIcon />
      <span className={styles.amount}>
        {amount} {plur('item', amount)} {plur('was', 'were', amount)} too large (10mb limit)
      </span>
      <Link href="/pricing" onClick={onClose}>
        <DashboardButton
          color={isMobile() ? 'white' : 'semi-transparent-black'}
          border
          style={{
            height: 38,
            fontSize: 16,
            fontWeight: 700,
            padding: isMobile() ? '9.5px 10px' : undefined,
          }}
        >
          {isMobile() ? 'Upgrade' : 'Upgrade to Pro'}
        </DashboardButton>
      </Link>
    </div>
  );
};

export default OverSizedInfo;
