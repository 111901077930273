import { mediaMobile } from '@/src/modules/ui/styled-utils';
import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  background: var(--fabric-color-bg-secondary);
  border: 1px solid var(--fabric-color-border-primary);
  color: var(--fabric-color-text-primary);
  border-radius: var(--fabric-radius-input);
  flex-grow: 1;
  min-height: 120px;

  font-weight: 400;
  &::placeholder {
    color: var(--fabric-color-text-placeholder) !important;
  }

  padding: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;

  transition:
    box-shadow 0.2s ease,
    border-color 0.2s ease;
  will-change: box-shadow, border-color;

  &:focus {
    outline: none;
    border-color: var(--fabric-color-app-primary);
  }

  ${mediaMobile} {
    background: var(--fabric-color-bg-secondary-button);
    border: none;
    min-height: 3rem;

    &:focus,
    &:focus-within {
      border: none;
    }
  }
`;
