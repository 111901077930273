import CloseIcon from '@/src/icons/CloseIcon';
import { pick } from '@/src/lib/store';
import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import { TextArea } from '@/src/modules/resources/components/NewResource/components/TextArea';
import { NewResourceInputContainer } from '@/src/modules/resources/components/NewResource/components/TextInputContainer';
import useUIStore from '@/src/store/ui';
import { useFormik } from 'formik';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import {
  NewResourceType,
  NewResourceTypesConfig,
} from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResource } from '@/src/modules/resources/mutations/useMutationCreateResource';
import { InputField } from '@/src/modules/ui/components/Field';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ClearInputButton } from '@/src/modules/ui/components/TextInput/TextInput';
import { getHrefWithoutHashParams, titleFromUrl } from '@/src/utils/formatters';
import { formatUrlWithProtocol } from '@/src/utils/url';
import { CreatePageWebnoteParams, FabricResourceTypes } from '@fabric/woody-client';
import isHotkey from 'is-hotkey';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  url: yup
    .string()
    .matches(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      'Invalid URL',
    )
    .required('URL is required'),
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

export const ModalContentLink: React.FC = () => {
  const { newModalOptions } = useUIStore((s) => pick(s, ['newModalOptions']), shallow);

  const { url, shared } = newModalOptions;
  const { destinationResourceId, selectedTags, handleClose } = useNewResourceContext();

  const { mutate: mutateCreateResource } = useMutationCreateResource();

  const formik = useFormik<{
    url: string;
    comment: string;
  }>({
    initialValues: {
      url: url || '',
      comment: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async ({ url, comment }, formik) => {
      const webnote: CreatePageWebnoteParams = {
        type: FabricResourceTypes.PAGE,
        context: {
          noteType: 'page',
        },
        pageTitle: titleFromUrl(url!),
        pageUrl: getHrefWithoutHashParams(formatUrlWithProtocol(url!)),
        parentResourceId: destinationResourceId || '',
        frontendWillUpload: false,
      };
      mutateCreateResource({ createResourceData: webnote, comment, tags: selectedTags });
      formik.setSubmitting(false);
      handleClose();
    },
  });

  const titleOptions = NewResourceTypesConfig[NewResourceType.Link];

  const inputRef = React.useRef<HTMLInputElement>(null);
  const commentRef = React.useRef<HTMLTextAreaElement>(null);

  return (
    <>
      <Flex
        flexGrow
        gap={24}
        direction="column"
        style={{ padding: '1rem' }}
        as="form"
        onSubmit={formik.handleSubmit}
      >
        <Header
          icon={<titleOptions.icon style={titleOptions.iconStyle} />}
          label={titleOptions.label}
          minLabel={titleOptions.minLabel}
        />
        <Flex direction="column" gap="elementsContainer" flexGrow>
          <InputField gap="sm">
            <NewResourceInputContainer>
              <input
                name="url"
                placeholder="Type or paste a link..."
                value={formik.values.url}
                onChange={formik.handleChange}
                onKeyDown={(e) => {
                  if (isHotkey('Enter', e)) {
                    e.preventDefault();
                    commentRef.current?.focus();
                  }
                }}
                data-testid="url-input"
                autoFocus={!shared}
                type="url"
                ref={inputRef}
              />
              {(formik.values.url?.length ?? 0) > 0 && (
                <ClearInputButton
                  tabIndex={-1}
                  onClick={() => {
                    formik.setFieldValue('url', '');
                    inputRef.current?.focus();
                  }}
                >
                  <CloseIcon />
                </ClearInputButton>
              )}
            </NewResourceInputContainer>
          </InputField>
          <InputField gap="sm" style={{ flexGrow: 1 }}>
            <TextArea
              ref={commentRef}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              placeholder="Say something about it... (optional)"
            />
            <InputField.ErrorMessage message={formik.errors.comment} />
          </InputField>
        </Flex>
        <SubmitFooter
          submitDisabled={!formik.values.url || formik.isSubmitting || !formik.isValid}
          onSubmit={formik.handleSubmit}
        />
      </Flex>
    </>
  );
};
