import { EditorJSData, FabricResourceTypes } from '@fabric/woody-client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ExpandedFdoc from '@/src/components/ExpandedFdoc/ExpandedFdoc';
import { DestinationButton } from '@/src/modules/resources/components/NewResource/components/DestinationButton';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResource } from '@/src/modules/resources/mutations/useMutationCreateResource';
import { useQueryResource } from '@/src/modules/resources/queries/useQueryResource';
import { cssVar } from '@/src/modules/ui/theme/variables';
import useUIStore from '@/src/store/ui';
import { Fdoc, ResourceState } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

/**
 * could deservers some more love, striped out old FdocNew
 */
export const ModalContentNoteDesktop: React.FC = () => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const { newModalOptions, setNewModalOptions } = useUIStore(
    (s) => ({ newModalOptions: s.newModalOptions, setNewModalOptions: s.setNewModalOptions }),
    shallow,
  );

  const { type, shared } = newModalOptions;
  const { destinationResourceId, selectedTags, handleClose, onSelectTag } = useNewResourceContext();

  const { mutateAsync: mutateCreateResource, ...mutationCreateResource } =
    useMutationCreateResource();

  useEffect(() => {
    if (!type) return;

    setTimeout(() => {
      // focus first input or contenteditable inside formRef
      // if shared we focus the second input (or only one if there is only one)
      // for inputs ignore type=file

      const foundInputs = formRef.current?.querySelectorAll(
        'input:not([type="file"]):not([data-no-autofocus="true"]), textarea, [contenteditable]',
      );

      if (!shared) {
        /**
         * find first non-empty input and focus it
         */
        const item = Array.from(foundInputs || []).find((item) => {
          if ('value' in item) {
            return (item.value as unknown as string).length === 0;
          }
          return false;
        });

        if (item) {
          (item as HTMLElement).focus();
          return;
        }
      }

      const input = !shared
        ? (foundInputs?.[0] as HTMLElement | null)
        : // second input or first if there is only one
          (foundInputs?.[foundInputs.length > 1 ? 1 : 0] as HTMLElement | null);

      if (input) input.focus();
    }, 10);
  }, [shared, type]);

  const savingNotepad = useRef(false);
  const [lastCreatedNotepadId, setLastCreatedNotepadId] = useState<string | null>(null);
  const lastCreatedNotepadIdRef = useRef<string | null>(null);

  useEffect(() => {
    lastCreatedNotepadIdRef.current = lastCreatedNotepadId;
  }, [lastCreatedNotepadId]);

  const { resource: lastCreatedNotepad } = useQueryResource(lastCreatedNotepadId ?? undefined, {
    refetchInterval: 45000,
  });

  const draftNotepad: OptimisticDraft<Fdoc> & { type: 'notepad' } = useMemo(
    () => ({
      id: 'draft',
      slug: null,
      isDraft: true,
      parentResourceId: null,
      originUrl: null,
      isDirectShared: false,
      isShared: false,
      stateProcessing: ResourceState.PENDING,
      commentCount: null,
      commentPinned: null,
      commenters: [],
      label: null,
      list: null,
      createdAt: new Date().toISOString(),
      modifiedAt: new Date().toISOString(),
      user: null,

      personal: {
        tags: [],
      },

      type: 'notepad',
      data: {
        title: '',
        editorjs: undefined,
        isYjsEnabled: false,
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
      isWebnote: false,
    }),
    [],
  );

  const handleNewNotepad = useCallback(
    async (draftFdoc: OptimisticDraft<Fdoc>, state?: Uint8Array) => {
      if (
        draftFdoc.type !== 'notepad' ||
        savingNotepad.current ||
        lastCreatedNotepadIdRef.current ||
        lastCreatedNotepad ||
        lastCreatedNotepadId ||
        mutationCreateResource.isPending
      )
        return;

      savingNotepad.current = true;

      const resource = await mutateCreateResource({
        createResourceData: {
          type: FabricResourceTypes.NOTEPAD,
          parentResourceId: destinationResourceId ?? '',
          title: draftFdoc.data.title,
          editorjs: draftFdoc.data.editorjs as EditorJSData,
          state,
        },
        tags: selectedTags,
      });

      if (!resource) {
        savingNotepad.current = false;
        return;
      }

      setNewModalOptions((options) => {
        /**
         * race condition
         * if the user closes the modal before the fdoc is created (handleClose)
         * we don't want to update the open options to type note again
         * because afterwards there's no way to reset the state.
         * This then blocks the drag and drop paste functionality (disabled when isNewOpenmodal has type)
         */
        if ('type' in options) {
          return {
            ...options,
            type: NewResourceType.Note,
            fabricEditorValue: undefined,
          };
        }
        return options;
      });

      lastCreatedNotepadIdRef.current = resource.id;
      setLastCreatedNotepadId(resource.id);

      savingNotepad.current = false;

      return resource.id;
    },
    [
      lastCreatedNotepad,
      lastCreatedNotepadId,
      mutationCreateResource.isPending,
      mutateCreateResource,
      destinationResourceId,
      setNewModalOptions,
      selectedTags,
    ],
  );

  const [notepadSidebarOpen, setNotepadSidebarOpen] = useState(false);

  const expandedFdoc = useMemo(
    () => lastCreatedNotepad ?? draftNotepad,
    [lastCreatedNotepad, draftNotepad],
  );

  const expandedFdocDraftProps = useMemo(
    () =>
      !lastCreatedNotepad
        ? {
            draftSaveFdoc: handleNewNotepad,
            draftOnSelectTag: onSelectTag,
            draftTags: selectedTags,
          }
        : {},
    [handleNewNotepad, lastCreatedNotepad, onSelectTag, selectedTags],
  );

  return (
    <ExpandedFdoc
      fdoc={expandedFdoc}
      onClose={handleClose}
      overrideSidebarOpen={notepadSidebarOpen}
      setOverrideSidebarOpen={setNotepadSidebarOpen}
      extra={<CustomDestinationButton />}
      {...expandedFdocDraftProps}
    />
  );
};

const CustomDestinationButton = styled(DestinationButton)`
  position: absolute;
  z-index: 126;
  bottom: 30px;
  z-index: 130;
  background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.3);
  color: ${cssVar['color-text-primary-reverse']};
  transition: 0.2s all;
  &:hover {
    background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.33);
  }
`;
