import { PrivateTag } from '@fabric/woody-client';
import React from 'react';

interface NewResourceContextProps {
  selectedTags: PrivateTag[];
  onSelectTag: (tag: PrivateTag, selected: boolean) => void;

  /**
   * destination
   */
  destinationResourceId?: string | null;
  handleOpenDestinationFolder: () => void;

  /**
   * controls
   */
  handleClose: () => void;
}
export const NewResourceContext = React.createContext<NewResourceContextProps>(
  {} as NewResourceContextProps,
);

export const useNewResourceContext = () => React.useContext(NewResourceContext);
