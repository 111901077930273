import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import { TextArea } from '@/src/modules/resources/components/NewResource/components/TextArea';
import { useFormik } from 'formik';
import React from 'react';
import { shallow } from 'zustand/shallow';

import OverSizedInfo from '@/src/components/OverSizedInfo/OverSizedInfo';
import { useResponsive } from '@/src/hooks/responsive';
import { useUnmount } from '@/src/hooks/useUnmount';
import useBulkUploaderStore from '@/src/lib/bulkUploader/store';
import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import {
  NewResourceType,
  NewResourceTypesConfig,
} from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { FileUpload } from '@/src/modules/resources/components/NewResource/ModalContentUpload/FileUpload';
import { InputField } from '@/src/modules/ui/components/Field';
import { Flex } from '@/src/modules/ui/components/Flex';
import useFileUploadStore from '@/src/store/fileUploadStore';
import TooDeepInfo from '@/src/views/FdocNew/OverSizedInfo';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

export const ModalContentUpload: React.FC = () => {
  const { isMobileView } = useResponsive();
  const { destinationResourceId, selectedTags, handleClose } = useNewResourceContext();
  const bulkUploader = useBulkUploaderStore((state) => state.bulkUploader, shallow);

  const { files, tooDeep, tooBigFiles, clearFiles } = useFileUploadStore((state) => {
    return {
      files: state.files,
      tooBigFiles: state.tooBigFiles,
      tooDeep: state.tooDeep,
      clearFiles: state.clearFiles,
    };
  }, shallow);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({ comment }, formik) => {
      bulkUploader?.addFiles(
        files.map((file) => ({
          file: file.file,
          metadata: {
            parentResourceId: destinationResourceId ?? '',
            path: file.metadata.path,
            comment,
            tags: selectedTags,
          },
        })),
      );

      formik.setSubmitting(false);
      handleClose();
    },
  });

  useUnmount(clearFiles, true);

  const titleOptions = NewResourceTypesConfig[NewResourceType.Upload];

  return (
    <Flex flexGrow gap={24} direction="column" style={{ padding: '1rem' }} as="form">
      <Header
        icon={<titleOptions.icon style={titleOptions.iconStyle} />}
        label={titleOptions.label}
        minLabel={titleOptions.minLabel}
      />
      <Flex direction="column" gap="elementsContainer" flexGrow>
        <FileUpload />

        {tooDeep && tooBigFiles === 0 && !isMobileView && <TooDeepInfo />}
        {tooBigFiles > 0 && !isMobileView && (
          <OverSizedInfo amount={tooBigFiles} onClose={handleClose} />
        )}

        <InputField gap="sm" style={{ flexGrow: 1 }}>
          <TextArea
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            placeholder="Say something about it... (optional)"
          />
          <InputField.ErrorMessage message={formik.errors.comment} />
        </InputField>
      </Flex>
      <SubmitFooter
        submitDisabled={files.length === 0 || formik.isSubmitting || !formik.isValid}
        onSubmit={formik.handleSubmit}
      />
    </Flex>
  );
};
