import AlertIcon from '@/public/images/icons/Alert.svg';
import { MAX_TREE_DEPTH_ALLOWED } from '@/src/lib/bulkUploader/uploader';
import clsx from 'clsx';
import plur from 'plur';
import React, { CSSProperties } from 'react';
import styles from './OverSizedInfo.module.scss';

const TooDeepInfo: React.FC<{
  className?: string;
  style?: CSSProperties;
}> = ({ className, style }) => {
  return (
    <div className={clsx(styles.root, className)} style={style}>
      <AlertIcon />
      <span className={styles.amount}>
        Fabric supports a max of {MAX_TREE_DEPTH_ALLOWED} nested{' '}
        {plur('folder', MAX_TREE_DEPTH_ALLOWED)}
      </span>
    </div>
  );
};

export default TooDeepInfo;
