import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { PrivateTag } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { tagQueryKeys } from './tagsQueryKeys';

/**
 * Fetches a specified tag by ID.
 */
export const useQueryTag = (
  tagId?: string,
  queryOptions?: Partial<QueryObserverOptions<PrivateTag>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: tagQueryKeys.tag(tagId || undefined),
    queryFn: async (params) => {
      const safeTagId = params.queryKey[1] as string;
      return client.v2({
        endpoint: '/v2/tags/{tagId}',
        params: {
          tagId: safeTagId,
        },
      });
    },
    placeholderData: keepPreviousData,
    refetchInterval: 90000,
    staleTime: 120000,
    ...queryOptions,
    enabled: isQueryEnabled([queryOptions?.enabled, !!tagId]),
  });

  return query;
};
