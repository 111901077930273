'use client';

import dynamic from 'next/dynamic';
import React, { useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import { shallow } from 'zustand/shallow';
import BulkUploaderDisplay from '../../components/BulkUploaderDisplay/BulkUploaderDisplay';
import DashboardFooter from '../../components/DashboardFooter/DashboardFooter';
import DragAndDropProvider from '../../components/DragAndDropProvider';
import { LightOverlay } from '../../components/LoadingOverlay';
import useAuthStore from '../../hooks/auth';
import { GlobalSearchProvider } from '../../hooks/experimentsearch';
import { isInMobile } from '../../hooks/mobile';
import { useResponsive } from '../../hooks/responsive';
import { desktopFloatUrls } from '../../hooks/todesktop';
import { useAltN } from '../../hooks/useAltN';
import { pick } from '../../lib/store';
import { fabricAppExtensionId, getFabricAppExtensionInstalled } from '../../services/extension';
import useUIStore, { useSearchOverlay } from '../../store/ui';
import { hasMacOSKbd } from '../../types/global';
import styles from './Dashboard.module.scss';

/**
 * TODO lazyload
 * currently when dynamically imported, it's causing layout shift due to variable width
 * This was resolved with useAnimationStore but it's not a good solution as it adds unnecessary
 * overhead to the whole app
 */
import DashboardSidebarV2 from '@/src/components/DashboardSidebarV2/DashboardSidebarV2';
import useDebounce from '@/src/hooks/debounce';
import { ExpandedFdoc_OLD } from '@/src/hooks/useExpandedFdoc';
import { DefaultSEO } from '@/src/lib/seo';
import { Assistant } from '@/src/modules/assistant/components/Assistant';
import { ModalResource } from '@/src/modules/resource-detail/components/ExpandedResource/ModalResource';
import { ModalNewResource } from '@/src/modules/resources/components/NewResource/ModalNewResource';
import { useQueryUserPreferences } from '@/src/modules/user/queries/useQueryUserPreferences';
import { NextSeo } from 'next-seo';
import { createGlobalStyle } from 'styled-components';

const Nudge = dynamic(() => import('../../views/Nudge'));
const AppPrompt = dynamic(() => import('../../components/AppPrompt/AppPrompt'));

const ShareGuidePrompt = dynamic(
  () => import('../../components/ShareGuidePrompt/ShareGuidePrompt'),
);

const SearchBarWithOverlay = dynamic(
  () => import('../../components/GlobalSearch/SearchBarWithOverlay'),
);
const ExperimentSearchBarWithOverlay = dynamic(
  () => import('../../components/GlobalSearch/ExperimentSearchBarWithOverlay'),
);

const VersionUpdatePromptModal = dynamic(
  () => import('../../components/VersionUpdatePromptModal/VersionUpdatePromptModal'),
);

interface DashboardProps extends React.PropsWithChildren {
  shouldRenderSidebar?: boolean;
  shouldRenderAssistant?: boolean;
}

const Dashboard = React.memo<DashboardProps>(
  ({ children, shouldRenderSidebar = true, shouldRenderAssistant = true }) => {
    /**
     * preload queries
     */
    useQueryUserPreferences();

    //
    const [showNudge, setShowNudge] = React.useState(false);
    const { requiredUpdateModalOpen, expandedFdocId, mobileKeyboardHeight, shareGuideShown } =
      useUIStore(
        (state) =>
          pick(state, [
            'expandedFdocId',
            'requiredUpdateModalOpen',
            'mobileKeyboardHeight',
            'shareGuideShown',
            'isNewModalOpen',
          ]),
        shallow,
      );

    const { openOverlay: openSearchOverlay } = useSearchOverlay();

    const { isDesktopView } = useResponsive();

    const { user, authStatus, isRedirecting } = useAuthStore(
      (state) => pick(state, ['user', 'authStatus', 'isRedirecting']),
      shallow,
    );

    const showLoading = useDebounce(
      useMemo(
        () => (authStatus === 'loading' && !user) || isRedirecting,
        [authStatus, user, isRedirecting],
      ),
      100,
    );

    const [appPrompt, setAppPrompt] = React.useState(false);
    const router = useRouter();

    // listen to CMD+F or CTRL+F
    useEffect(() => {
      if (router.pathname === '/global-search' || desktopFloatUrls.includes(router.pathname))
        return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'f' && ((e.metaKey && hasMacOSKbd()) || (e.ctrlKey && !hasMacOSKbd()))) {
          e.preventDefault();
          openSearchOverlay('global');
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [router, openSearchOverlay]);

    useAltN();
    const appPromptActive = isDesktopView && appPrompt;

    return (
      <>
        <DashboardGlobalStyles
          mobileKeyboardHeight={mobileKeyboardHeight}
          isExpandedFdoc={!!expandedFdocId}
        />
        <NextSeo {...DefaultSEO} />
        {showLoading ? (
          <LightOverlay />
        ) : (
          <DragAndDropProvider>
            {requiredUpdateModalOpen && <VersionUpdatePromptModal />}
            {isDesktopView ? <ExpandedFdoc_OLD /> : <ModalResource />}
            <section className={styles.dashboard} suppressHydrationWarning>
              <ModalNewResource />

              {shouldRenderSidebar && <DashboardSidebarV2 />}

              {appPromptActive && <AppPrompt setAppPrompt={setAppPrompt} />}
              {isInMobile() && !shareGuideShown && <ShareGuidePrompt />}

              {showNudge && (
                <div className={styles.nudge_container}>
                  <Nudge
                    title="Give your browser superpowers"
                    description={`Save anything on the internet, in an instant.\nSee your notes on top of the websites they’re about.`}
                    webStoreExtensionId={fabricAppExtensionId.value}
                    isExtensionInstalled={getFabricAppExtensionInstalled}
                    onBack={() => setShowNudge(false)}
                  />
                </div>
              )}

              <SearchBarWithOverlay />

              <GlobalSearchProvider>
                <ExperimentSearchBarWithOverlay />
              </GlobalSearchProvider>

              <div className={styles.dashboard__content}>
                {children}
                <BulkUploaderDisplay />
              </div>
              {shouldRenderAssistant && <Assistant />}
              <DashboardFooter />
            </section>
          </DragAndDropProvider>
        )}
      </>
    );
  },
);

Dashboard.displayName = 'Dashboard';

/**
 * render on client only
 * DnD is causing hydration error
 * Removing isMounted logic from inside of the component
 */
export default dynamic(() => Promise.resolve(Dashboard), {
  ssr: false,
});

const DashboardGlobalStyles = createGlobalStyle<{
  mobileKeyboardHeight: number;
  isExpandedFdoc?: boolean;
}>`
body {
  /**
      * Moved the safe offset for floating elements here because the floating element might be defined
      * as a non-child of the dashboard, so it's better to define it here so it affects globally
    */
  --safe-offset-floating-elements: calc(var(--footer-height, 0px) +
    var(--safe-dashboard-bottom-offset, 0px) + var(--bulk-uploader-height, 0px));
}
`;
